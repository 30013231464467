import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/layouts/main-layout";
import SportsLayout from "../../components/layouts/sports-layout";
import CourseInfo from "../../components/course-info";
import Seo from "../../components/seo";

const Wingfoilen = () => {
  const headerImage = (
    <StaticImage
      src="../../images/heroimages/tidens_website_WIG_header.jpg"
      alt="Wingsurfen und Wingfoilen lernen bei Tidens Surfhuus"
      width={2048}
      height={680}
      quality={90}
      className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
      objectFit="cover"
    />
  );

  const description = (
    <div>
      <p>
        NEU – Wingsurfen / Wingfoilen wurde erst vor wenigen Jahren erfunden und
        hat rasend schnell die Wassersportwelt erobert. Und auch auf Langeoog
        kannst du diese Trendsportart jetzt erlernen!
      </p>
      <p>
        Wingsurfen ist der ideale Einstieg in den Wassersport für Menschen jeden
        Alters (ab 8 Jahren) und ohne Vorkenntnisse. Mit dem Wing in beiden
        Händen und einem großen Surfbrett kommt man schnell ins Fahren.
        Erfahrene Surfer und Fortgeschrittene können noch einen Schritt
        weitergehen und das Wingfoilen erlernen. Beim Foilen hebt sich das Brett
        aus dem Wasser heraus und du schwebst nahezu geräuschlos über das
        Wasser. Außerdem ist Foilen auch bei extrem wenig oder böigem Wind
        möglich und ist somit eine perfekte Leichtwindalternative.
      </p>
      <p>
        Wingsurfen / Wingfoilen eignet sich außerdem hervorragend, um auch zu
        Hause, zum Beispiel auf einem See, aufs Wasser zu kommen.
      </p>
    </div>
  );

  const rentalDescription =
    "Du hast schon ausreichend Kenntnisse, um alleine zu üben? Dann kannst du dir bei uns auch alles ausleihen, was du zum Wingsurfen oder Wingfoilen benötigst.";

  const courses = [
    <CourseInfo
      title="Wingsurfen Schnupperkurs"
      price="60"
      duration="2h"
      people="8"
      description="Du willst diese neue Wassersportart kennenlernen, die aussieht wie eine Mischung aus Kitesurfen und Windsurfen? Probiere es aus! Komm vorbei und wir bringen dir im Schnupperkurs Wingsurfen die Grundlagen im Umgang mit dem Wing und einem großen Board bei."
      sport="WIG"
      type="TRY"
      requestOnly={false}
    />,
    <CourseInfo
      title="Wingsurfen Grundkurs"
      price="119"
      duration="4h (2 Tage) "
      people="8"
      description="Im Grundkurs Wingsurfen lernst du Schritt für Schritt den sicheren Umgang mit dem Wing an Land und im Wasser. Anschließend bringen wir dir bei, kontrolliert mit dem Wing und einem Anfängerboard zu fahren und verschiedene Kurse zu steuern."
      sport="WIG"
      type="BEG"
      requestOnly={false}
    />,
    <CourseInfo
      title="Wingsurfen Aufbaukurs"
      price="60"
      duration="2h"
      people="8"
      description="Wenn du den Grundkurs Wingsurfen gemeistert hast, besteht die Möglichkeit an einem Aufbaukurs teilzunehmen. In diesem Kurs helfen wir dir dabei, das sichere Fahren auf allen Kursen zu verbessern und bringen dir das Wenden und Halsen bei. Im Anschluss an diesen Kurs bist du dann bereit, mit einem Foilboard zu üben und damit „abzuheben“."
      sport="WIG"
      type="ADV"
      requestOnly={true}
    />,
      <CourseInfo
      title="Wingfoilen Grundkurs"
      price="169"
      duration="4h (2 Tage) "
      people="2"
      description="Wenn du bereits sicher mit einem Wing und einem großen Surfboard fahren kannst, besteht der nächste Schritt darin, zu lernen, wie man mit einem Foilboard über das Wasser fliegt. In diesem Kurs zeigen wir dir, wie du sicher mit dem Foilboard ins Fahren kommst und kontrolliert abhebst. Je nach Vorerfahrung, solltest du davon ausgehen, dass du einen ganzen Kurstag benötigst, um mit einem kleinen Board OHNE Foil sicher ins Fahren zu kommen. Am zweiten Tag kannst du dann mit einem Foilboard deine ersten Flugversuche starten. Bist du bereit für deine erste Flugstunde?"
      sport="WIG"
      type="BEG"
      requestOnly={false}
    />,
    <CourseInfo
      title="Wingfoilen Aufbaukurs"
      price="89"
      duration="2h"
      people="2"
      description="Der Inhalt des Kurses richtet sich ganz nach Deinen Bedürfnissen."
      requestOnly={true}
    />,
    <CourseInfo
      title="Privatstunde"
      price="99"
      duration="1h"
      people="1"
      description="Du möchtest etwas ganz Bestimmtes lernen, deine erste Halse foilen oder bist einfach nur auf der Suche nach intensiver Betreuung? Gerne unterrichten wir dich auch in einer Privatstunde. Bitte beachte, dass wir Privatstunden nur auf Anfrage anbieten können."
      requestOnly={true}
    />,
    // <CourseInfo
    //   title="VDWS Lizenz"
    //   price="50"
    //   duration="1h"
    //   description="Im Anschluss an den Grundkurs kannst du bei uns auch eine VDWS-Lizenz machen. Diese gilt als Nachweis für deine Fähigkeiten im Bereich Wingsurfen und wird zum Beispiel für das Ausleihen von Material benötigt. Im Preis sind das Workbook Wingsurfen, die Prüfung sowie die Lizenz enthalten."
    //   requestOnly={true}
    // />,
  ];

  const image1 = (
    <StaticImage
      src="../../images/galery/wing/tidens_website_WIG-1.jpg"
      alt="Wingsurfen und Wingfoilen lernen bei Tidens Surfhuus Galery 1"
      width={750}
      height={500}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[45vh]"
    />
  );

  const image2 = (
    <StaticImage
      src="../../images/galery/wing/tidens_website_WIG-2.jpg"
      alt="Wingsurfen und Wingfoilen lernen bei Tidens Surfhuus Galery 2"
      width={750}
      height={500}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[45vh]"
    />
  );

  const image3 = (
    <StaticImage
      src="../../images/galery/wing/tidens_website_WIG-3.jpg"
      alt="Wingsurfen und Wingfoilen lernen bei Tidens Surfhuus Galery 3"
      width={750}
      height={500}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[45vh]"
    />
  );

  const image5 = (
    <StaticImage
      src="../../images/galery/wing/tidens_website_WIG-5.jpg"
      alt="Wingsurfen und Wingfoilen lernen bei Tidens Surfhuus Galery 5"
      width={750}
      height={500}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[45vh]"
    />
  );

  const image6 = (
    <StaticImage
      src="../../images/galery/wing/tidens_website_WIG-6.jpg"
      alt="Wingsurfen und Wingfoilen lernen bei Tidens Surfhuus Galery 6"
      width={750}
      height={500}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[45vh]"
    />
  );

  const image7 = (
    <StaticImage
      src="../../images/galery/wing/tidens_website_WIG-7.jpg"
      alt="Wingsurfen und Wingfoilen lernen bei Tidens Surfhuus Galery 4"
      width={750}
      height={500}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[45vh]"
    />
  );
  const image8 = (
    <StaticImage
      src="../../images/galery/wing/tidens_website_WIG-8.jpg"
      alt="Wingsurfen und Wingfoilen lernen bei Tidens Surfhuus Galery 4"
      width={750}
      height={500}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[45vh]"
    />
  );
  const image9 = (
    <StaticImage
      src="../../images/galery/wing/tidens_website_WIG-9.jpg"
      alt="Wingsurfen und Wingfoilen lernen bei Tidens Surfhuus Galery 4"
      width={750}
      height={500}
      objectFit="cover"
      className="w-full aspect-square lg:aspect-auto lg:max-h-[45vh]"
    />
  );

  const images = [
    image2,
    image3,
    image6,
    image5,
    image1,
    image7,
    image8,
    image9,
  ];

  return (
    <Layout>
      <Seo
        title="Wingsurf und Wingfoil Kurse"
        description="Du möchtest Wingsurfen oder Wingfoilen lernen? Wir bieten Wingsurf- und Wingfoilkurse für Anfänger und Fortgeschrittene auf der Nordseeinsel Langeoog an."
      />
      <SportsLayout
        title="Wingsurfen / Wingfoilen"
        sport="wingfoilen"
        image={headerImage}
        description={description}
        rental_description={rentalDescription}
        courses={courses}
        images={images}
      />
    </Layout>
  );
};

export default Wingfoilen;
